<template>
  <v-container fluid class="grey lighten-5">
    <!-- Service Fee Card -->
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title class="headline">Producer Service Fee</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-avatar big color="blue lighten-5">
                  <v-icon dark>directions_car</v-icon>
                </v-avatar>
                <span class="subtitle-1 font-regular ml-4"
                  >Service Fee For Delivery:</span
                >
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-text-field
                  v-model="serviceFees.deliveryPct"
                  label="Fee"
                  outlined
                  type="number"
                  suffix="%"
                  :rules="[rules.positive, rules.hundred]"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-text-field
                  v-model="serviceFees.producerDeliveryPct"
                  label="Producer Pays %"
                  outlined
                  type="number"
                  suffix="%"
                  :rules="[
                    rules.positive,
                    rules.hundred,
                    rules.checkDeliveryFee
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-avatar big color="blue lighten-5">
                  <v-icon>business_center</v-icon>
                </v-avatar>
                <span class="subtitle-1 font-regular ml-4"
                  >Service Fee For Pickup:</span
                >
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-text-field
                  v-model="serviceFees.pickupPct"
                  label="Fee"
                  outlined
                  type="number"
                  suffix="%"
                  :rules="[rules.required, rules.positive, rules.hundred]"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-text-field
                  v-model="serviceFees.producerPickupPct"
                  label="Producer Pays %"
                  outlined
                  type="number"
                  suffix="%"
                  :rules="[rules.positive, rules.hundred, rules.checkPickupFee]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="ma-4" x-large @click="saveFee"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Subsidy Fee Card -->
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title class="headline">Delivery Subsidy Fees</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-avatar big color="green">
                  <v-icon dark>directions_car</v-icon>
                </v-avatar>
                <span class="subtitle-1 font-regular ml-4"
                  >Change Delivery Subsidy Amount:</span
                >
              </v-col>
            </v-row>
            <v-row
              class="ml-4"
              v-for="(item, key) in newDeliverySubsidy"
              :key="key"
            >
              <v-col cols="3">
                <v-text-field
                  v-model="item.from"
                  :rules="[rules.required, rules.positive]"
                  @input="val => changeFrom(val, key)"
                  label="From"
                  prefix="$"
                  type="number"
                  placeholder="50"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="item.to"
                  :rules="[rules.required, rules.positive]"
                  @input="val => changeTo(val, key)"
                  label="To"
                  type="number"
                  prefix="$"
                  placeholder="45"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="item.amount"
                  :rules="[rules.required, rules.positive]"
                  @input="val => changeAmount(val, key)"
                  label="Discount Amount"
                  prefix="$"
                  placeholder="5"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="subsidyDisabled"
              color="primary"
              class="ma-4"
              x-large
              @click=";(saveDialog = true), (key = 'default_delivery_subsidy')"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Service Fee Confirmation Dialog -->
    <v-dialog v-model="saveDialog" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon color="red" large>error</v-icon>
          <h4 class="ml-4">Change Value?</h4>
        </v-card-title>
        <v-card-subtitle class="mt-2">
          <span>
            Changing this values will affect the entirety of the TakeIn App and
            its Consumers and Producers.
            <br />Continue?
          </span>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="saveDialog = false">Cancel</v-btn>
          <v-btn color="red" dark @click="saveSubsidy()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminServiceFees',
  data() {
    return {
      serviceFees: {
        deliveryPct: 15,
        pickupPct: 10,
        producerDeliveryPct: 0,
        producerPickupPct: 0
      },
      saveDialog: false,
      serviceFeeDisabled: true,
      subsidyDisabled: true,
      newDeliverySubsidy: [],
      rules: {
        required: value => !!value || 'Value Required',
        positive: value => value >= 0 || 'Value Must Be Positive',
        hundred: value => value <= 100 || 'Value Must Be Less Than 100',
        checkDeliveryFee: value =>
          value <= this.serviceFees.deliveryPct ||
          `Value Must Be Less Than ${this.serviceFees.deliveryPct}%`,
        checkPickupFee: value =>
          value <= this.serviceFees.pickupPct ||
          `Value Must Be Less Than  ${this.serviceFees.pickupPct}%`
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      serviceFeez: 'serviceFees',
      deliverySubsidy: 'deliverySubsidy'
    })
  },

  mounted() {
    this.$store.dispatch('getOrderFees').then(() => {
      this.serviceFees = this.serviceFeez
      this.newDeliverySubsidy = this.deliverySubsidy
    })
  },
  methods: {
    saveFee() {
      this.saveDialog = false
      this.serviceFees.producerDeliveryPct = parseFloat(
        this.serviceFees.producerDeliveryPct
      )
      this.serviceFees.producerPickupPct = parseFloat(
        this.serviceFees.producerPickupPct
      )
      this.serviceFees.deliveryPct = parseFloat(this.serviceFees.deliveryPct)
      this.serviceFees.pickupPct = parseFloat(this.serviceFees.pickupPct)
      this.$store
        .dispatch('saveAdminSettings', { serviceFees: this.serviceFees })
        .then(() => {
          console.log('Save Successful')
        })
    },
    saveSubsidy() {
      this.saveDialog = false
      let amount = 0
      if (this.key === 'service_fee') {
        console.log('Changing Service Fee to', this.newServiceFee)
        // convert service fee back to dec
        amount = this.newServiceFee / 100
        this.serviceFee = this.newServiceFee
      } else if (this.key === 'default_delivery_subsidy') {
        amount = this.newDeliverySubsidy
      }

      let payload = {
        amount: amount,
        key: this.key
      }

      this.$store.dispatch('saveOrderFees', payload).then(() => {
        console.log('Save Successful')
      })
    },
    _validateSubsidy(key) {
      let subsidy = this.newDeliverySubsidy[key]
      // check if every value is existing and greater than 0
      if (
        !subsidy.from ||
        subsidy.from <= 0 ||
        !subsidy.to ||
        subsidy.to <= 0 ||
        !subsidy.amount ||
        subsidy.amount <= 0
      ) {
        this.subsidyDisabled = true
        return
      }

      // check if from value is less than
      if (subsidy.from > subsidy.to) {
        console.log('foo2')
        this.subsidyDisabled = true
        return
      }
      // check if from and to values do not overlap
      if (
        key < this.newDeliverySubsidy.length - 1 &&
        subsidy.to >= this.newDeliverySubsidy[key + 1].from
      ) {
        console.log('foo3')
        this.subsidyDisabled = true
        return
      }

      this.subsidyDisabled = false
      return
    },
    changeFrom(value, key) {
      this.newDeliverySubsidy[key].from = Number(value)
      this._validateSubsidy(key)
    },
    changeTo(value, key) {
      this.newDeliverySubsidy[key].to = Number(value)
      this._validateSubsidy(key)
    },
    changeAmount(value, key) {
      this.newDeliverySubsidy[key].amount = Number(value)
      this._validateSubsidy(key)
    }
  }
}
</script>
