<template>
  <v-container v-if="profile" fluid class="grey lighten-5">
    <OperatingCities></OperatingCities>
    <OrderFees></OrderFees>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import OperatingCities from './OperatingCities'
import OrderFees from './AdminServiceFees'

export default {
  components: {
    OperatingCities,
    OrderFees
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile'
    })
  },
  mounted() {},
  methods: {}
}
</script>
