<template>
  <v-container fluid class="grey lighten-5">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title class="headline">Operating Cities</v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <v-avatar big color="indigo">
                  <v-icon dark>location_city</v-icon>
                </v-avatar>
                <span class="subtitle-1 font-regular ml-4"
                  >Currently Supported Cities</span
                >
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="city"
                  :items="cities"
                  label="cities"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      cities: [],
      city: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      operatingCities: 'operatingCities'
    })
  },
  mounted() {
    this.$store.dispatch('getOperatingCities').then(() => {
      // gets city names from cities object
      this.cities = this.operatingCities.map(c => c.city)
      if (this.cities.length > 0) {
        this.city = this.cities[0]
      }
    })
  },
  methods: {}
}
</script>
